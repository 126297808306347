<template>
    <div class="rider-page">
        <div class="title">
            骑手
        </div>
        <div class="search-div">
            <el-button type="primary" size="small" @click="openAddDialog(false)">添加骑手</el-button>
            <div style="display: flex;">
               <el-input size='medium' v-model="search" style="margin-right: 10px;"></el-input>
               <el-button type="primary" size="small" @click="searchList">搜索</el-button>
            </div>
        </div>

        <div class="table-container">
            <el-table border :data="riderList">
                <el-table-column prop="user_name" align="center" label="名称"  width="180">
                </el-table-column>
                <el-table-column prop="name" align="center" label="真实姓名" width="180">
                </el-table-column>
                <el-table-column prop="gender" align="center" label="性别" width="180">
                </el-table-column>
                <el-table-column prop="phone" align="center" label="电话" width="180">
                </el-table-column>
                <el-table-column align="center" label="创建时间">
                    <template slot-scope="scope">
                        {{scope.row.create_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column prop="更新时间" align="center" label="更新时间">
                    <template slot-scope="scope">
                        {{scope.row.update_time | datefmt('YYYY-MM-DD HH:mm:ss') }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作">
                    <template slot-scope="scope">
                        <span>
                         <el-button type="text" size="small" style="margin-right: 10px" @click="openAddDialog(true, scope.row)">编辑</el-button>
                         </span>
                         <span>
                             <el-popconfirm
                                confirmButtonText='好的'
                                cancelButtonText='不用了'
                                icon="el-icon-info"
                                iconColor="red"
                                @onConfirm="deleteRider(scope.row.rise_id)"
                                title="确定删除该骑手吗？">
                                <el-button slot="reference" type="text" size="small">删除</el-button>
                            </el-popconfirm>
                         </span>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination class="page-pageination" background layout="prev, pager, next" :total="total" :page-size="size"
            :current-page.sync="page" @current-change="getRiderList">
            </el-pagination>
        </div>

        <el-dialog :title="`${isEdit ? '编辑' : '新增'}骑手`" class="add-dialog" :close-on-click-modal="false"
             width="400px" :visible.sync="addRiderDialog">
            <el-form :model="addRiderData" :rules="rules" ref="addRiderForm" label-width="78px">
                <el-form-item label="骑手名称" prop='userName'>
                    <el-input v-model="addRiderData.userName" size="medium"></el-input>
                </el-form-item>
                <el-form-item label="密码" :prop="isEdit ? '' : 'password'">
                    <el-input size='medium' type="password" v-model="addRiderData.password"></el-input>
                </el-form-item>
                <el-form-item label="性别" >
                    <el-select v-model="addRiderData.gender" size="medium" placeholder="请选择">
                        <el-option label="男" value="男"></el-option>
                        <el-option label="女" value="女"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="真实姓名" prop="name">
                    <el-input size='medium' v-model="addRiderData.name"></el-input>
                </el-form-item>
                <el-form-item label="电话号码" prop="phone">
                    <el-input size='medium' v-model="addRiderData.phone"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addRiderDialog = false">取 消</el-button>
                <el-button type="primary" @click="sureSaveRider('addRiderForm')">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>

export default {
    name: 'Rider',
    data () {
        return {
            // 验证规则
            rules: {
                userName: [
                    { required: true, message: '请输入用户名', trigger: 'change' }
                ],
                password: [{ required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 6, max: 16, message: '长度在 6 到 16 个字符' }
                ],
                name: [
                    { required: true, message: '请输入真实姓名', trigger: 'change' }
                ],
                phone: [
                    { required: true, message: '请输入电话号码', trigger: 'change' }
                    // {
                    //     pattern: /^1[3|4|5|7|8][0-9]\d{8}$/,
                    //     message: '请输入正确的手机号码'
                    // }
                ]
            },

            // 快递业务列表
            riderList: [],
            page: 1,
            size: 10,
            total: 0,
            search: '',

            // 新增/编辑数据
            addRiderData: {
                userName: '',
                name: '',
                gender: '男',
                password: '',
                phone: ''
            },
            addRiderDialog: false,
            isEdit: false
        }
    },
    mounted () {
        this.getRiderList()
    },
    methods: {
        // 获取快递业务列表
        getRiderList () {
            this.axios.post('/rise/list', {
                page: this.page,
                size: this.size,
                search: this.search
            }).then((res) => {
                this.riderList = res.data.list
                this.total = res.data.count
                console.log(res)
            })
        },

        searchList () {
            this.page = 1
            this.getRiderList()
        },

        /**
         * 打开弹窗
         * @param isEdit 是否为编辑
         * @param riderData 为编辑时，骑手数据
         */
        openAddDialog (isEdit, riderData) {
            this.isEdit = isEdit
            this.addRiderDialog = true
            if (isEdit) {
                this.addRiderData = {
                    riderId: riderData.rise_id,
                    userName: riderData.user_name,
                    name: riderData.name,
                    gender: riderData.gender,
                    password: '',
                    phone: riderData.phone
                }
            } else {
                this.addRiderData = {
                    userName: '',
                    name: '',
                    gender: '男',
                    password: '',
                    phone: ''
                }

                this.$nextTick(() => {
                    this.$refs.addRiderForm.clearValidate()
                })
            }

            console.log(riderData)
        },

        /**
         * 删除骑手
         * @param editRiderId 删除ID
         */
        deleteRider (ediRiderId) {
            this.axios.post('/rise/delete', {
                rise_id: ediRiderId
            }).then((res) => {
                if (res) {
                    this.getRiderList()
                    this.$message.success('删除成功')
                }
            })
        },

        /**
         * 保存骑手数据
         * @param formName 表单名称
         */
        sureSaveRider (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const requestUrl = this.isEdit ? '/rise/update' : '/rise/add'
                    const { riderId, userName, password, gender, name, phone } = { ...this.addRiderData }
                    this.axios.post(requestUrl, {
                        rise_id: riderId,
                        user_name: userName,
                        password,
                        gender,
                        name,
                        phone
                    }).then((res) => {
                        console.log(res)
                        this.addRiderDialog = false
                        this.$message.success(`成功${this.isEdit ? '编辑' : '添加'}骑手`)
                        this.getRiderList()
                    }).catch(() => {
                        // console.log(error)
                    })
                } else {
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss">
    .rider-page{
        .title{
            padding: 14px;
            // margin-bottom: 18px;
            border-bottom: 1px solid #dddddd;
            font-size: 28px;
        }

        .search-div{
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
            border-bottom: 1px solid #dddddd;
        }

        .table-container{
            margin: 10px 20px 0;
        }

        .page-pageination{
            padding: 10px 0;
            text-align: right;
        }

        .add-dialog{
            .el-input,.el-select{
                width: 240px;
            }

            .el-dialog__body{
                padding: 10px 20px;
            }
        }
    }
</style>
